@import './typo3.css';

@import '../../node_modules/swiper/swiper.min.css';
@import '../../node_modules/swiper/modules/a11y/a11y.min.css';
@import '../../node_modules/swiper/modules/navigation/navigation.min.css';
@import '../../node_modules/swiper/modules/pagination/pagination.min.css';
@import '../../node_modules/leaflet/dist/leaflet.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	/*
	@font-face {
		font-family: 'Gotu';
		font-style: normal;
		font-weight: 400;
		src: local('Gotu'),
		url('../fonts/gotu-v4-latin-ext_latin-regular.woff2') format('woff2'), / * Chrome 26+, Opera 23+, Firefox 39+ * /
		url('../fonts/gotu-v4-latin-ext_latin-regular.woff') format('woff'); / * Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ * /
	}
	*/
	@font-face {
		font-family: 'Inter';
		src: url('../fonts/Inter-VariableFont_slnt,wght.ttf')
			format('truetype-variations');
		font-weight: 400;
		font-variation-settings: 'wght' 375;
	}
	@font-face {
		font-family: 'Inter';
		src: url('../fonts/Inter-VariableFont_slnt,wght.ttf')
			format('truetype-variations');
		font-weight: bold;
		font-variation-settings: 'wght' 600;
	}

	@font-face {
		font-family: 'DroidSans';
		font-style: normal;
		font-weight: 400;
		src: local('Droid Sans'),
			url('../fonts/droidsans-webfont.woff2') format('woff2'),
			/* Chrome 26+, Opera 23+, Firefox 39+ */
				url('../fonts/droidsans-webfont.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}
	@font-face {
		font-family: 'DroidSans';
		font-style: normal;
		font-weight: bold;
		src: local('Droid Sans'),
			url('../fonts/droidsans-bold-webfont.woff2') format('woff2'),
			/* Chrome 26+, Opera 23+, Firefox 39+ */
				url('../fonts/droidsans-bold-webfont.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	}

	html {
		scroll-behavior: smooth;
		scroll-padding-top: 100px;
	}

	@media screen and (prefers-reduced-motion: reduce) {
		html {
			scroll-behavior: auto;
		}
	}

	body {
		background: #bdb8b8;
	}
}

@layer components {
	.contrast-child,
	:hover > .hover\:contrast-child {
		@apply bg-inherit bg-clip-text text-transparent;
		filter: contrast(9) grayscale(100%) invert(1) contrast(9);
	}

	.ab-container {
		max-width: 1600px;
	}

	.frame {
		h2,
		h3,
		h4,
		h5,
		h6 {
			@apply my-4 font-bold;
		}

		h1 {
			@apply hidden;
		}

		h2 {
			@apply text-4xl hidden;
		}

		h3 {
			@apply text-xl sm:text-2xl md:text-3xl;
		}

		h4 {
			@apply text-lg sm:text-xl;
		}

		h5 {
			@apply text-lg md:text-xl;
		}

		h6 {
			@apply text-lg;
		}

		details {
			@apply border-t border-b py-1 first-of-type:mt-2 last-of-type:mb-2;

			& + details {
				@apply border-t-0;
			}

			> summary {
				@apply flex items-center gap-2 cursor-pointer list-none
				text-gray-700 hover:text-black text-black;

				&::marker,
				&::-webkit-details-marker {
					@apply hidden;
				}

				&::before {
					@apply content-["+"] text-3xl font-bold;
				}
			}

			&:open {
				> summary {
					@apply text-black;

					&::before {
						@apply content-["-"];
					}
				}
			}
		}
	}

	.ce-bodytext {
		@apply my-4 max-w-[800px];
	}

	.ab-bodytext,
	.ce-bodytext {
		header {
			h2,
			h3,
			h4,
			h5,
			h6 {
				@apply first:mt-0;
			}
		}

		ul,
		ol,
		p,
		table {
			@apply my-2;
		}

		li {
			@apply my-1;
		}

		ul {
			@apply list-disc pl-6;
		}

		ol {
			@apply list-decimal pl-6;
		}

		a {
			@apply border-b border-dotted;

			color: #96295a;
			border-color: currentColor;
		}
	}

	.ab-nav {
		summary {
			&::marker,
			&::-webkit-details-marker {
				@apply hidden;
			}
		}
	}

	.ab-main-nav {
		@apply flex flex-col font-display;

		li {
			@apply flex flex-col;
		}

		ul li {
			@apply pl-6;
		}

		a {
			@apply py-2 px-2;
		}
	}

	.news-item {
		@apply hyphens;

		h3 {
			@apply text-lg sm:text-xl md:text-xl;
		}

		img {
			@apply my-4;
		}

		p {
			@apply max-w-[800px];
		}
	}

	.ab-stage {
		--swiper-theme-color: white;
		--swiper-pagination-bullet-inactive-color: #ccc;

		.swiper-pagination {
			bottom: 0;
			@apply md:pb-1 md:text-right md:pr-2;

			&-bullet {
				width: 20px;
				border-radius: 4px;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			width: 60px;
			height: 60px;
			background: #1e3e6dcf;
			backdrop-filter: blur(5px);
			border-radius: 100%;
		}

		.swiper-button-next {
			padding-left: 5px;
		}

		.swiper-button-prev {
			padding-right: 8px;
		}
	}

	.ab-circle {
		box-sizing: border-box;
		border: 6px solid #e9a2ba;
		text-align: center;
		padding: 17px 5px;
		line-height: 1.1;
		letter-spacing: -0.03em;
		border-radius: 100%;
		background: #b7003b;
		color: white;
		margin: 0 auto;
		transition: transform 200ms ease-out;
	}

	.training-courses {
		width: 100%;
		margin: 20px 0 0;
		font: 0.85em/1.3 sans-serif;

		a {
			color: #006b8c;
			border-color: #006b8c;
		}

		th {
			padding: 7px 0 5px 30px;
			text-align: left;
			font-weight: normal;
			font-size: 1.1em;
		}

		&__title {
			border-top: 1px solid #006b8c;
			padding: 7px 30px 9px 97px;
			vertical-align: top;
			text-indent: -67px;
		}

		&__date {
			border-top: 1px solid #006b8c;
			padding: 7px 0 9px 30px;
			vertical-align: top;

			a {
				color: #444;
				border-bottom: 0;
			}
		}
	}

	.leaflet-container {
		height: 380px;
		margin: 10px 0 -8px 0;
	}

	.form-group {
		@apply mt-4;
	}

	.control-label {
		@apply block;
	}

	input:not([type=checkbox]):not([type=radio]).form-control,
	textarea.form-control,
	select.form-control {
		@apply w-full border border-gray-400 rounded p-2 px-4;
	}

	.form-check-label {
		@apply block py-2;
	}

	.actions {
		@apply mt-4;
	}

	.btn-group {
	}

	.btn {
		@apply block bg-gray-200 rounded px-4 w-full py-2 hover:bg-gray-300;
	}

	.row {
		@apply flex flex-wrap -mx-2;
	}

	.col-6 {
		@apply w-full md:w-1/2 px-2;
	}
}

@layer utilities {
	.hyphens {
		hyphens: auto;
	}
}

